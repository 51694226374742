import { api } from '../axios'

const UserService = (() => {
  const changePassword = async data => {
    const res = await api.patch(`/user/change-password`, data)
    return res
  }

  return {
    changePassword
  }
})()

export default UserService
