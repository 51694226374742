<template>
  <div>
    <div class="card">
      <h1 class="mt-0 mb-4">{{ $t('changePassword.title') }}</h1>
      <form @submit.prevent="changePassword" class="d-flex flex-column gap-2">
        <Field
          :label="$t('changePassword.form.currentPassword.label')"
          v-model="model.currentPassword"
          :error="errors.currentPassword"
          required
          type="password"
        />
        <Field
          :label="$t('changePassword.form.newPassword.label')"
          v-model="model.newPassword"
          :error="errors.newPassword"
          required
          type="password"
        />
        <Field
          :label="$t('changePassword.form.confirmPassword.label')"
          v-model="model.confirmedPassword"
          :error="errors.confirmedPassword"
          required
          type="password"
        />
        <div class="btn-center">
          <button class="btn btn-primary">
            <span>{{ $t('changePassword.form.change') }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Field from '../../../elements/Field.vue'
import { object, string, ref } from 'yup'
import { yupToKV } from '../../../utils/yup'
import UserService from '../../../services/user.service'

export default {
  components: {
    Field
  },
  data() {
    return {
      model: {
        currentPassword: '',
        newPassword: '',
        confirmedPassword: ''
      },
      loading: false,
      errors: {}
    }
  },
  methods: {
    async changePassword() {
      this.errors = {}
      try {
        await this.schema
          .validate(this.model, { abortEarly: false })
          .catch(err => {
            this.errors = yupToKV(err) // Convert Yup errors to a key-value format
          })

        if (this.schema.isValidSync(this.model)) {
          const res = await UserService.changePassword(this.model)

          // Reset form and display success message
          this.model.currentPassword = ''
          this.model.newPassword = ''
          this.model.confirmedPassword = ''
          this.toast(this.$t('changePassword.form.toasts.passwordChanged'))
        }
      } catch (error) {
        this.toast(error.response.data.message, {
          type: 'error'
        })
      }
    }
  },
  computed: {
    schema() {
      return object().shape({
        currentPassword: string().required(
          this.$t('changePassword.form.errors.required.currentPassword')
        ),
        newPassword: string()
          .required(this.$t('changePassword.form.errors.required.newPassword'))
          .min(
            6,
            this.$t('changePassword.form.errors.minLength.newPassword', {
              num: 6
            })
          )
          .matches(
            /^(?=.*\d)/,
            this.$t('changePassword.form.errors.numeric.newPassword')
          )
          .matches(
            /^(?=.*[!-\/:-@[-`{-~])/,
            this.$t('changePassword.form.errors.specialChar.newPassword')
          )
          .matches(
            /^(?=.*[a-z])/,
            this.$t('changePassword.form.errors.lowercase.newPassword')
          )
          .matches(
            /^(?=.*[A-Z])/,
            this.$t('changePassword.form.errors.uppercase.newPassword')
          ),
        confirmedPassword: string()
          .required(
            this.$t('changePassword.form.errors.required.confirmPassword')
          )
          .oneOf(
            [ref('newPassword'), null],
            this.$t('changePassword.form.errors.passwordsMustMatch')
          )
      })
    }
  }
}
</script>

<style scoped>
.card {
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 1rem;
}

.gap-2 {
  gap: 0.5rem;
}
.btn-center {
  display: grid;
  place-items: center;
}
</style>
